/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../GameList/gameList.css'
import { ElipseRed, image404, LogoWithoutText } from '../../../../../components/imgExport';
import { Tooltip } from 'antd';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ChooseBet } from '../../../../../api/betting/bettingApi';
import { InfoBetMGM } from '../../../../../api/homePage/homePage_V2Api';
import { ICON_TEAM } from '../../../../../env';
import { covertDataGames, covertDataGamesOld, covertDataOptionMarkets, covertDataOptionMarketsOld, getChangeClass_V3, getChangeColorText, getChangeColor_V3 } from '../../../../../until';
interface GameDetailProps {
    sport?: string
    tournament: string
    item: InfoBetMGM
    listChooseBet: ChooseBet[]
    competitionsLiveOld: { [key: string]: InfoBetMGM[] }
    chooseBet?: (
        tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", dateEvent: string, live: boolean, id?: number | string, optionId?: number | string, homeScore?: number, awayScore?: number, dateEventServer?: string
    ) => void
    checkUpdateStrightBetsSlip: (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", color: string, homeScore?: number, awayScore?: number, id?: number | string) => void
    decimal: string
    inputFilter?: string
}
const GameLiveDeTail_V2: React.FC<GameDetailProps> = ({
    sport, tournament, item, listChooseBet, competitionsLiveOld, decimal, inputFilter,
    chooseBet, checkUpdateStrightBetsSlip

}) => {
    const { games, startDate, participants, optionMarkets } = item
    const { spread, totals, moneyLine } = covertDataGames(games)
    var spreadOption: any
    var totalsOption: any
    var moneyLineOption: any
    if (games.length === 0) {
        spreadOption = covertDataOptionMarkets(optionMarkets).spread
        totalsOption = covertDataOptionMarkets(optionMarkets).totals
        moneyLineOption = covertDataOptionMarkets(optionMarkets).moneyLine
        if (!spreadOption) {
            spreadOption = optionMarkets.filter((market) => market.name.value === "Run Line Spread")[0]
        }
    }
    var spreadRunLine: any
    if (!spread) {
        spreadRunLine = optionMarkets.filter((game) => game.name.value === "Run Line Spread")[0]
    }

    var xx: any
    var spreadOld: any
    var totalsOld: any
    var moneyLineOld: any
    if (competitionsLiveOld && competitionsLiveOld[tournament] && competitionsLiveOld[tournament].length > 0) {
        xx = competitionsLiveOld[tournament].filter((x) => x.id === item.id)[0]
        if (xx) {
            if (games.length === 0) {
                if (covertDataOptionMarketsOld(xx.optionMarkets)) {
                    spreadOld = covertDataOptionMarketsOld(xx.optionMarkets).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.optionMarkets.filter((market: any) => market.name.value === "Run Line Spread")[0]
                    }
                    // console.log(covertDataOptionMarketsOld(xx.optionMarkets))
                    totalsOld = covertDataOptionMarketsOld(xx.optionMarkets).totalsOld
                    moneyLineOld = covertDataOptionMarketsOld(xx.optionMarkets).moneyLineOld
                }
            } else {
                if (covertDataGamesOld(xx.games)) {
                    spreadOld = covertDataGamesOld(xx.games).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.games.filter((games: any) => games.name.value === "Run Line Spread")[0]
                    }
                    totalsOld = covertDataGamesOld(xx.games).totalsOld
                    moneyLineOld = covertDataGamesOld(xx.games).moneyLineOld
                }
            }

        }
        // { spreadOld, totalsOld, moneyLineOld } =  covertDataGamesOld(xx.games)
    }

    // const { spreadOld, totalsOld, moneyLineOld } = (xx) ? covertDataGamesOld(xx.games) : {}
    const handelBet = (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", id?: number | string) => {
        const game = participants[1].name.value + " / " + participants[0].name.value
        chooseBet?.(tournament, game, teamChoose, scoreChoose, lineBetting, startDate, true, item.id, id,
            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
            startDate
        )
        //Thiếu truyền tỉ số hiện tại ở kèo MGM vào đây
    }
    // item.scoreboard?.period

    return (
        <>
            <div className='flex flex-col'>
                <div className='flex justify-between'>
                    <div className='w-[220px] rounded-xl border border-[#2B313E] bg-[#202531] flex items-center justify-between px-2 py-1'>
                        <div className='flex items-center gap-2'>
                            <img className="pr-1" src={ElipseRed} alt="ElipseRed" />
                            <span className="fw-[500] text-base text-[#FFFFFF]">{item.scoreboard?.period ? item.scoreboard?.period + (item.scoreboard?.indicator ? item.scoreboard?.indicator : "") : startDate}</span>
                        </div>
                        {/* <div className='w-[18px] border border-[#2B303D]'></div> */}
                        {/* <span className='text-[#FFFFFF] fw-[500] text-base'> {formatTime(startDate)}</span> */}
                    </div>
                    <div className='bg-header rounded-tl-xl rounded-tr-xl flex justify-end py-3 gap-1'>
                        <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px] pr-3'>SPREAD</span>
                        <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px] pr-3'>TOTAL</span>
                        <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px] pr-3'>MONEY LINE</span>
                    </div>
                </div>
            </div>
            <div className={` flex items-center w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
            >
                {
                    games.length !== 0 && (
                        <div className={`!pl-0 gameDetail flex w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className="gameTime bg-[#131620] flex flex-col items-center"
                                // onClick={() => navigator("/detailPage")}
                                >
                                    <div className='flex flex-col items-start gap-3'>
                                        <div className='flex items-center h-[56px] gap-2'>
                                            <img
                                                className='w-[30px] h-[30px]'
                                                // src={LogoWithoutText}
                                                src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                alt="team1"
                                                onError={(e) => {
                                                    e.currentTarget.src = image404;
                                                }} />
                                            <span className='text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                        </div>
                                        {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                        <div className='flex items-center h-[56px] gap-2'>
                                            <img
                                                className=' w-[30px] h-[30px]'
                                                // src={LogoWithoutText}
                                                src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                alt="team1"
                                                onError={(e) => {
                                                    e.currentTarget.src = image404;
                                                }} />
                                            <span className='text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio pl-10">
                                    <div className="homeRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>

                                    <div className="awayRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>


                                </div>
                                :
                                <div className="gameRatio pl-10">
                                    <div className="homeRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                        </div>
                                        {(sport === "NFL" || sport === "NBA") && item.scoreboard?.period === "Halftime-close" ? (
                                            <>
                                                {spread?.visibility === "Visible" ? (
                                                    <div
                                                        className={
                                                            `ratioDetail !p-0 custom-border-live justify-center w-[98px] h-[62px] pl-2 flex items-center flex-col relative
                                        ${getChangeColor_V3(
                                                                (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0,
                                                                participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? spreadOld?.results[0]?.odds : 0),
                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                    spread?.results[0]?.id
                                                                )
                                                            )}
                                        `
                                                        }
                                                    >
                                                        {
                                                            spread?.results[0]?.visibility === "Visible" && spread?.results[0]?.attr ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : (Number(spread?.results[0]?.americanOdds) > 0 ? '+' + spread?.results[0]?.americanOdds : spread?.results[0]?.americanOdds)), "Spread", spread?.results[0]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${spread?.results[0]?.attr}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spread?.results[0]?.odds.toFixed(2) : spread?.results[0]?.americanOdds)}`}</b></p>

                                                                </div> :
                                                                spreadRunLine?.results[0]?.attr ?
                                                                    <div className='w-full h-full flex flex-col justify-center items-center'
                                                                        onClick={() => handelBet?.(participants[0].name.value, spreadRunLine?.results[0]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds : (Number(spreadRunLine?.results[0]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[0]?.price?.americanOdds}` : spreadRunLine?.results[0]?.price?.americanOdds)), "Spread", spreadRunLine?.results[0]?.id)}
                                                                    >
                                                                        <p className='text-[#E4E7F1]'>{`${spreadRunLine?.results[0]?.attr}`}</p>
                                                                        <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds.toFixed(2) : spreadRunLine?.results[0]?.price?.americanOdds)}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`ratioDetail !p-0 custom-border-live justify-center w-[98px] h-[62px] pl-2 flex items-center flex-col relative`}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {totals?.visibility === "Visible" ? (
                                                    <div
                                                        className={
                                                            `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                        ${getChangeColor_V3(
                                                                (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0,
                                                                participants[0].name.value, "Total", startDate, listChooseBet,
                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0),
                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                    totals?.results[0]?.id
                                                                )
                                                            )}
                                    `}
                                                    >
                                                        {
                                                            totals?.results[0]?.visibility === "Visible" && totals?.results[0]?.name.value ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", totals?.results[0]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${totals?.results[0]?.name.value}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? totals?.results[0]?.odds.toFixed(2) : (Number(totals?.results[0]?.americanOdds) > 0 ? `+${totals?.results[0]?.americanOdds}` : totals?.results[0]?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {moneyLine?.visibility === "Visible" ? (
                                                    <div
                                                        className={
                                                            `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                        ${getChangeColor_V3(
                                                                (decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0,
                                                                participants[0].name.value, "Money", startDate, listChooseBet,
                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0),
                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                    moneyLine?.results[0]?.id
                                                                )
                                                            )}
                                        `}
                                                    >
                                                        {
                                                            moneyLine?.results[0]?.visibility === "Visible" && moneyLine?.results[0]?.odds ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", moneyLine?.results[0]?.id)}
                                                                >
                                                                    <b className='!text-[#E4E7F1]'>{(decimal !== 'American' ? moneyLine?.results[0]?.odds.toFixed(2) : (Number(moneyLine?.results[0]?.americanOdds) > 0 ? `+${moneyLine?.results[0]?.americanOdds}` : moneyLine?.results[0]?.americanOdds))}</b>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>

                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>

                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>

                                    <div className="awayRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                        </div>
                                        {(sport === "NFL" || sport === "NBA") && item.scoreboard?.period === "Halftime-close" ? (
                                            <>
                                                {spread?.visibility === "Visible" ? (
                                                    <div
                                                        className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                    ${getChangeColor_V3(
                                                            (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0,
                                                            participants[1].name.value, "Spread", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0),
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                spread?.results[1]?.id
                                                            )
                                                        )}
                                    `}
                                                    >
                                                        {
                                                            spread?.results[1].visibility === "Visible" && spread?.results[1]?.attr ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", spread?.results[1]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${spread?.results[1]?.attr}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spread?.results[1]?.odds.toFixed(2) : (Number(spread?.results[1]?.americanOdds) > 0 ? '+' + spread?.results[1]?.americanOdds : spread?.results[1]?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                spreadRunLine?.results[1]?.attr ?
                                                                    <div className='w-full h-full flex flex-col justify-center items-center'
                                                                        onClick={() => handelBet?.(participants[1].name.value, spreadRunLine?.results[1]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds : spreadRunLine?.results[1]?.price?.americanOdds), "Spread", spreadRunLine?.results[1]?.id)}
                                                                    >
                                                                        <p className='text-[#E4E7F1]'>{`${spreadRunLine?.results[1]?.attr}`}</p>
                                                                        <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds.toFixed(2) : (Number(spreadRunLine?.results[1]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[1]?.price?.americanOdds}` : spreadRunLine?.results[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {totals?.visibility === "Visible" ? (
                                                    <div
                                                        className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                    ${getChangeColor_V3(
                                                            (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0,
                                                            participants[1].name.value, "Total", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, totals?.results[1]?.attr + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0),
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                totals?.results[1]?.id
                                                            )
                                                        )}
                                    `}
                                                    >
                                                        {
                                                            totals?.results[1].visibility === "Visible" && totals?.results[1]?.name.value ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, totals?.results[1]?.name.value + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", totals?.results[1]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1] '>{`${totals?.results[1]?.name.value}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? totals?.results[1]?.odds.toFixed(2) : (Number(totals?.results[1]?.americanOdds) > 0 ? `+${totals?.results[1]?.americanOdds}` : totals?.results[1]?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>

                                                    </div>
                                                )}

                                                {moneyLine?.visibility === "Visible" ? (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                    ${getChangeColor_V3(
                                                            (decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0,
                                                            participants[1].name.value, "Money", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0),
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                moneyLine?.results[1]?.id
                                                            )
                                                        )}
                                    `}
                                                    >
                                                        {
                                                            moneyLine?.results[1].visibility === "Visible" && moneyLine?.results[1]?.odds ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", moneyLine?.results[1]?.id)}
                                                                >
                                                                    <b className='!text-[#E4E7F1]'>{(decimal !== 'American' ? moneyLine?.results[1]?.odds.toFixed(2) : (Number(moneyLine?.results[1]?.americanOdds) > 0 ? `+${moneyLine?.results[1]?.americanOdds}` : moneyLine?.results[1]?.americanOdds))}</b>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}

                                    </div>


                                </div>
                            }

                        </div >
                    )}

                {
                    games.length === 0 && (
                        <div className={`!pl-0 gameDetail flex w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className="gameTime bg-[#131620] flex flex-col items-center"
                                // onClick={() => navigator("/detailPage")}
                                >


                                    <div className='flex flex-col items-start gap-3'>
                                        <div className='flex items-center h-[56px] gap-2'>
                                            <img
                                                className='w-[30px] h-[30px]'
                                                // src={LogoWithoutText}
                                                src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                alt="team1"
                                                onError={(e) => {
                                                    e.currentTarget.src = image404;
                                                }} />
                                            <span className='text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                        </div>
                                        {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                        <div className='flex items-center h-[56px] gap-2'>
                                            <img
                                                className=' w-[30px] h-[30px]'
                                                // src={LogoWithoutText}
                                                src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                alt="team1"
                                                onError={(e) => {
                                                    e.currentTarget.src = image404;
                                                }} />
                                            <span className='text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio pl-10">
                                    <div className="homeRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>

                                    <div className="awayRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                        <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>


                                </div>
                                :
                                <div className="gameRatio pl-10">
                                    <div className="homeRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                        </div>
                                        {(sport === "NFL" || sport === "NBA") && item.scoreboard?.period === "Halftime-close" ? (
                                            <>
                                                {spreadOption?.status === 'Visible' ? (
                                                    <div
                                                        className={
                                                            `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                                            ${getChangeColor_V3(
                                                                (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0,
                                                                participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds?.toString() : spreadOption?.options[0]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0),
                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                    spreadOption?.options[0]?.id
                                                                )
                                                            )}
                            `
                                                        }
                                                    >
                                                        {
                                                            spreadOption?.options[0]?.status === 'Visible' && spreadOption?.options[0]?.attr ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), "Spread", spreadOption?.options[0]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${spreadOption?.options[0]?.attr}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spreadOption?.options[0]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[0]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[0]?.price?.americanOdds}` : spreadOption?.options[0]?.price?.americanOdds))}`}</b></p>

                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {totalsOption?.status === 'Visible' ? (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                                        ${getChangeColor_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0,
                                                            participants[0].name.value, "Total", startDate, listChooseBet,
                                                            // totals?.results[0]?.name.value
                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds?.toString() : totalsOption?.options[0]?.price?.americanOdds?.toString()), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0),
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                totalsOption?.options[0]?.id
                                                            )
                                                        )}
                        `}
                                                    >
                                                        {
                                                            totalsOption?.options[0]?.status === 'Visible' && totalsOption?.options[0]?.name.value ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), "Total", totalsOption?.options[0]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${totalsOption?.options[0]?.name.value}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? totalsOption?.options[0]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[0]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[0]?.price?.americanOdds}` : totalsOption?.options[0]?.price?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}

                                                {moneyLineOption?.status === 'Visible' ? (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                                            ${getChangeColor_V3(
                                                            (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0,
                                                            participants[0].name.value, "Money", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds,
                                                                moneyLineOld?.options[0]?.id) : 0)
                                                                ,
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255])

                                                        )}
                        `}
                                                    >
                                                        {
                                                            moneyLineOption?.options[0]?.status === 'Visible' && moneyLineOption?.options[0]?.price?.odds ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[0]?.id)}
                                                                >
                                                                    <b className='!text-[#E4E7F1]'>{(decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toFixed(2) : (Number(moneyLineOption?.options[0]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[0]?.price?.americanOdds}` : moneyLineOption?.options[0]?.price?.americanOdds))}</b>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}
                                    </div>

                                    <div className="awayRaito flex">
                                        <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                                            <b className='pr-1'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                        </div>
                                        {(sport === "NFL" || sport === "NBA") && item.scoreboard?.period === "Halftime-close" ? (
                                            <>
                                                {spreadOption?.status === 'Visible' ? (
                                                    <div
                                                        className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                    ${getChangeColor_V3(
                                                            (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0,
                                                            participants[1].name.value, "Spread", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.odds?.toString() : spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds,
                                                                spreadOld?.options[1]?.id) : 0)
                                                                ,
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]
                                                            )
                                                        )}
                                    `}
                                                    >
                                                        {
                                                            spreadOption?.options[1]?.status === 'Visible' && spreadOption?.options[1]?.attr ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, spreadOption?.options[1]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), "Spread", spreadOption?.options[1]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1]'>{`${spreadOption?.options[1]?.attr}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? spreadOption?.options[1]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[1]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[1]?.price?.americanOdds}` : spreadOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {totalsOption?.status === 'Visible' ? (
                                                    <div
                                                        className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative 
                                                            ${getChangeColor_V3(
                                                            (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0,
                                                            participants[1].name.value, "Total", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + totalsOption?.options[1]?.price?.odds?.toString(), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds,
                                                                totalsOld?.options[1]?.id) : 0)
                                                                ,
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255])
                                                        )}
                                                        `}
                                                    >
                                                        {
                                                            totalsOption?.options[1]?.status === 'Visible' && totalsOption?.options[1]?.name.value ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), "Total", totalsOption?.options[1]?.id)}
                                                                >
                                                                    <p className='text-[#E4E7F1] '>{`${totalsOption?.options[1]?.name.value}`}</p>
                                                                    <p className='text-[#E4E7F1]'><b>{`${(decimal !== 'American' ? totalsOption?.options[1]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[1]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[1]?.price?.americanOdds}` : totalsOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {moneyLineOption?.status === 'Visible' ? (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative ${getChangeColor_V3(
                                                            (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0,
                                                            participants[1].name.value, "Money", startDate, listChooseBet,
                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds,
                                                                moneyLineOld?.options[1]?.id) : 0)
                                                                ,
                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255])
                                                        )}`}
                                                    >
                                                        {
                                                            moneyLineOption?.options[1]?.status === 'Visible' && moneyLineOption?.options[1]?.price?.odds ?
                                                                <div className='w-full h-full flex flex-col justify-center items-center'
                                                                    onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[1]?.id)}
                                                                ><b className='!text-[#E4E7F1]'>{(decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLineOption?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[1]?.price?.americanOdds}` : moneyLineOption?.options[1]?.price?.americanOdds))}</b>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col  `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>


                                </div>
                            }

                        </div >
                    )}
            </div>


        </>
    )
}

export default GameLiveDeTail_V2;