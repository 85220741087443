
import { useDispatch } from 'react-redux';
import { createAlert } from '../store/slices/toastSlice';

const useToast = () => {
    const dispatch = useDispatch();

    const pushToast = (message: string = "", icon: "success" | "error" | "warn" | "info", duration: number = 2000) => {
        if (message && message?.length > 0) {
            dispatch(createAlert({
                message,
                icon,
                duration
            }))
        } else {
            const messageTmp = "Cannot connect to server"
            dispatch(createAlert({
                message: messageTmp,
                icon: "warn",
                duration: duration
            }))
        }
    }

    return pushToast;
}

export default useToast;