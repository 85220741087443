/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Login from '../page/Login';
import MLBComponent_V2 from './MLBComponent_V2';
import NBAComponent_V2 from './NBAComponent_V2';
// import DetailGame from './DetailGame';
import SoccerMainNew from './SoccerMainNew';
// import DashBoard_V2 from './Dashboard_V2';
import OTPConfirm from '../components/OTPConfirm';
import NFLComponent_V2 from './NFLComponent_V2';
import PageNotFound from './pageNotFound';
import { Modal } from 'antd';
import io from 'socket.io-client';
import { API_URL } from '../env';
import * as loginApi from '../api/login/loginApi';
import useResetData from '../hook/useResetData';
import NCAAFComponent_V2 from './NCAAFComponent_V2';

const Router: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socketLogin, setSocketLogin] = useState<any>(null);
    const [showSpan, setShowSpan] = useState(true);
    const [showNLF, setShowNLF] = useState(true);
    const [showMLB, setShowMLB] = useState(true);
    const [showModalNoInternet, setShowModalNoInternet] = useState(false);
    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false);
    const [OTPCode, setOTPCode] = useState<string>("")
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { resetAllData, resetLoginOut } = useResetData()

    const useOnlineStatus = () => {
        const [isOnline, setIsOnline] = useState<boolean>(window.navigator.onLine);

        useEffect(() => {
            const handleOnline = () => setIsOnline(true);
            const handleOffline = () => setIsOnline(false);

            window.addEventListener('online', handleOnline);
            window.addEventListener('offline', handleOffline);

            return () => {
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);
            };
        }, []);

        return isOnline;
    };

    const isOnline = useOnlineStatus();

    const getAuthcodeByToken = async () => {
        const ketqua = await loginApi.getAuthcodeByToken()
        if (ketqua.authCode) {
            setOTPCode(ketqua.authCode)
        }
    }

    const checkLogout = async (data: any) => {
        if (location.pathname !== '/otp-confirm') {
            if (OTPCode) {
                if (data.length > 0) {
                    const updateList = data.map((item: any) => item.authcode);
                    if (updateList.includes(OTPCode)) {
                        //clear data
                        await resetLoginOut()
                        navigate("/otp-confirm")

                    }
                }
            }
        }
    }

    useEffect(() => {
        if (isOnline) {
            if (showModalNoInternet) {
                window.location.reload()
            }
            // setShowModalNoInternet(false)
        } else {
            setShowModalNoInternet(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline])

    useEffect(() => {
        setShowSpan(location.pathname === '/' || location.pathname === '/login' || location.pathname === '/NBA' || location.pathname === '/Soccer');
        if (location.pathname === '/') {
            navigate("/Soccer_V1")
        }
    }, [location.pathname]);
    useEffect(() => {
        setShowNLF(location.pathname === '/NFL');
    }, [location.pathname]);
    useEffect(() => {
        setShowMLB(location.pathname === '/MLB');
    }, [location.pathname]);

    useEffect(() => {
        getAuthcodeByToken()
    }, []);
    useEffect(() => {
        const socketInstance = io(API_URL);
        setSocketLogin(socketInstance);
        socketInstance.on('connect', () => {
            console.log('Connected to server Login');
        });
        socketInstance.on('logged-bill-acceptor', async (data) => {
            checkLogout(data)
        });
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPCode]);

    return (
        <>
            <img className='w-full hidden' src={`/pagenotfound.jpg`} alt="" />
            <Modal
                centered
                open={showModalNoInternet}
                width={"100%"}
                closeIcon={false}
                footer={false}
                className='!mx-0 !max-w-full'

            >
                <p className='text-center text-3xl font-bold'>You don't have an internet connection</p>
                <img className='w-full' src={`/pagenotfound.jpg`} alt="" />
            </Modal>
            <Routes>
                <Route path='/otp-confirm' element={<OTPConfirm />} />
                <Route path='/login' element={<Login />} />
                {/* <Route path='/' element={
                    <DashBoard_V2
                        isHomePage={isHomePage}
                        setIsHomePage={setIsHomePage}
                        showSpan={showSpan}
                        setShowSpan={setShowSpan}
                        showNLF={showNLF}
                        setShowNLF={setShowSpan}
                        showMLB={showMLB}
                        setShowMLB={setShowSpan}
                    />
                }>
                    <Route path='/detailPage' element={<DetailGame />} />
                </Route> */}
                <Route path='/MLB' element={
                    <MLBComponent_V2
                        isHomePage={isHomePage}
                        setIsHomePage={setIsHomePage}
                        showSpan={showSpan}
                        setShowSpan={setShowSpan}
                        showNLF={showNLF}
                        setShowNLF={setShowSpan}
                        showMLB={showMLB}
                        setShowMLB={setShowSpan}
                    />
                }></Route>

                <Route path='/NFL' element={
                    <NFLComponent_V2
                        isHomePage={isHomePage}
                        setIsHomePage={setIsHomePage}
                        showSpan={showSpan}
                        setShowSpan={setShowSpan}
                        showNLF={showNLF}
                        setShowNLF={setShowSpan}
                        showMLB={showMLB}
                        setShowMLB={setShowSpan}
                    />
                }></Route>
                <Route path='/NBA' element={
                    <NBAComponent_V2
                        isHomePage={isHomePage}
                        setIsHomePage={setIsHomePage}
                        showSpan={showSpan}
                        setShowSpan={setShowSpan}
                        showNLF={showNLF}
                        setShowNLF={setShowSpan}
                        showMLB={showMLB}
                        setShowMLB={setShowSpan}
                    />
                }></Route>

                <Route path='/NCAAF' element={
                    <NCAAFComponent_V2
                        isHomePage={isHomePage}
                        setIsHomePage={setIsHomePage}
                        showSpan={showSpan}
                        setShowSpan={setShowSpan}
                        showNLF={showNLF}
                        setShowNLF={setShowSpan}
                        showMLB={showMLB}
                        setShowMLB={setShowSpan}
                    />
                }></Route>

                <Route path='/Soccer_V1' element={<SoccerMainNew
                    isHomePage={isHomePage}
                    setIsHomePage={setIsHomePage}
                    showSpan={showSpan}
                    setShowSpan={setShowSpan}
                    showNLF={showNLF}
                    setShowNLF={setShowSpan}
                    showMLB={showMLB}
                    setShowMLB={setShowSpan}
                />} />
                <Route path='/pagenotfound' element={<PageNotFound />} />
            </Routes>
        </>
    );
}

export default Router;
