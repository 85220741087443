const getToken = () => {
    return localStorage.getItem('token');
}

const getTokenNew = () => {
    return '12345678-abcd-1234-ef00-1234567890ab';
}

const setToken = (token: string) => {
    localStorage.setItem('token', token);
}

const removeToken = () => {
    localStorage.removeItem('token');
}

export default {
    getToken, getTokenNew, setToken, removeToken
}