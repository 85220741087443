/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Button } from 'antd';
import type { TabsProps } from 'antd';
import { Checkbox } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import BetSlipTab from '../../BetSlipTab/BetSlipTab';
import { InfoBetTeaserTMP, InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from '../../../../../api/betting/bettingApi';
import ItemStraight from './Component/ItemStraight';
import ItemParlay from './Component/ItemParlay';
import ItemTeaser from './Component/ItemTeaser';
import IconSlider from './Component/Teaser';
import './styles.css'
import { infoBettings } from '../../../../../api/homePage/homePageApi';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';

interface GameDetailProps {
  tab?: "homepage" | "MLB" | "NBA" | "NFL" | "NCAAF"
  menu?: any
  keyGameTab?: "gameOnline" | "gameOffline"
  keyBetTabs: string
  totalStake: number
  pointTeaser?: number
  listAddStrightBetsSlip?: InfoStrightBetsSlipTMP[]
  listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
  listTeaserBetsSlip?: InfoBetTeaserTMP[]
  listChooseBet?: bettingApi.ChooseBet[]

  setKeyBetTabs: (value: string) => void
  rStrightBetsSlipByIndex: (value: number) => void
  clearAll: () => void;
  setListaddStrightBetsSlip: (value: InfoStrightBetsSlipTMP[]) => void
  setListParlayBetsSlip?: (value: InfoParlayBetsSlipTMP[]) => void
  setListTeaserBetsSlip?: (value: InfoBetTeaserTMP[]) => void
  setTotalStake: (index: number) => void
  setPointTeaser?: (value: number) => void


  chooseElement: string
  arrayActiveItem: string[]
  setArrayActiveItem: (id: string[]) => void
  decimal?: string
  betting?: infoBettings
}

const BetTabs: React.FC<GameDetailProps> = ({
  tab, menu, keyGameTab, keyBetTabs, totalStake, pointTeaser,
  listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip, listChooseBet,
  setKeyBetTabs, rStrightBetsSlipByIndex, clearAll, setListaddStrightBetsSlip, setListParlayBetsSlip, setListTeaserBetsSlip, setTotalStake, setPointTeaser,
  chooseElement,
  arrayActiveItem,
  setArrayActiveItem,
  decimal,
  betting
}) => {

  const pushToast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [settingClicked, setSettingClicked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showTabs, setShowTabs] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllCheckedParlay, setIsAllCheckedParlay] = useState(false);
  const [isAllCheckedTeaser, setIsAllCheckedTeaser] = useState(false);
  const settingbet = menu?.map((item: any) => item.key)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHiddenBox = (id: string) => {
    setArrayActiveItem(arrayActiveItem.filter((item) => item !== id))
  }

  const handleCheckAll = (value: boolean) => {
    if (listAddStrightBetsSlip?.length) {
      const updatedArray = listAddStrightBetsSlip.map(item => ({
        ...item,
        isCheck: value,
        // win: item.amount * item.scoreChoose
      }));
      setListaddStrightBetsSlip(updatedArray)
    }
  }
  const handleCheckAllParlay = (value: boolean) => {
    if (listParlayBetsSlip?.length) {
      const updatedArray = listParlayBetsSlip.map(item => ({
        ...item,
        isCheck: value
      }));
      setListParlayBetsSlip?.(updatedArray)
    }
  }

  const handleCheckAllTeaser = (value: boolean) => {
    if (listTeaserBetsSlip?.length) {
      const updatedArray = listTeaserBetsSlip.map(item => ({
        ...item,
        isCheck: value
      }));
      setListTeaserBetsSlip?.(updatedArray)
    }

  }

  useMemo(() => {
    if (listAddStrightBetsSlip?.length) {
      const allTrue: boolean = listAddStrightBetsSlip.every(item => item.isCheck);
      setIsAllChecked(allTrue)
    }
  }, [listAddStrightBetsSlip])

  useEffect(() => {
    if (listParlayBetsSlip?.length) {
      const allTrue: boolean = listParlayBetsSlip.every(item => item.isCheck);
      setIsAllCheckedParlay(allTrue)
    }
  }, [listParlayBetsSlip])

  useEffect(() => {
    if (listTeaserBetsSlip?.length) {
      const allTrue: boolean = listTeaserBetsSlip.every(item => item.isCheck);
      setIsAllCheckedTeaser(allTrue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParlayBetsSlip])

  const items: TabsProps['items'] = keyGameTab === "gameOffline" ?
    ([
      {
        key: '1',
        label: settingbet.includes('1') ? 'Straight' : '',
        children:
          <div className={`betTabs text-base pl-2 flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
            {
              listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                <ItemStraight
                  tab={tab}
                  key={i}
                  index={i}
                  item={item}
                  listAddStrightBetsSlip={listAddStrightBetsSlip}
                  rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                  setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                  setTotalStake={setTotalStake}
                  decimal={decimal}
                />
              ))
            }

            {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
            <Checkbox
              checked={isAllChecked}
              onChange={(e) => handleCheckAll(e.target.checked)}
            ></Checkbox>
            <div className='flex flex-row-reverse'>
              <Button
                className='!flex items-center pl-2 pr-1'
                type="text"
                onClick={clearAll}
              >
                <DeleteOutlined
                  className='text-[#898989]'
                />
                <span className='text-black font-[500]'
                >Clear All
                </span>
              </Button>
            </div>
          </div> */}
            <BetSlipTab showTabs={showTabs} />
          </div>,
      },
      {
        disabled: !settingbet.includes('2'),
        key: '2',
        label: settingbet.includes('2') ? 'Parlay' : '',
        children: <div className={`betTabs text-base pl-2 flex flex-col gap-2 ? "hidden" : ""} ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
          {
            listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
              <ItemParlay
                tab={tab}
                key={i}
                index={i}
                item={item}
                listParlayBetsSlip={listParlayBetsSlip}
                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                setListParlayBetsSlip={setListParlayBetsSlip}
                decimal={decimal}
              // setTotalStake={setTotalStake}

              />
            ))}

          {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
          <Checkbox
            checked={isAllCheckedParlay}
            onChange={(e) => handleCheckAllParlay(e.target.checked)}
          ></Checkbox>
          <div className='flex flex-row-reverse'>
            <Button
              className='!flex items-center pl-2 pr-1'
              type="text"
              onClick={clearAll}
            >
              <DeleteOutlined
                className='text-[#898989]'
              />
              <span className='text-black font-[500]'
              >Clear All
              </span>
            </Button>
          </div>
        </div> */}
          <BetSlipTab showTabs={showTabs} />
        </div>,
      },
      {
        disabled: !settingbet.includes('3'),
        key: settingbet.includes('3') ? '3' : '',
        label: settingbet.includes('3') ? 'Teaser' : '',
        children: <div className={`betTabs text-base ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
          <div className='flex flex-col gap-2'>
            <div className='flex gap-1'>
              {/* <span className='text-[#D5D5D5]'>Speard</span> */}
              <span className='font-bold'>
                {pointTeaser} - Points</span>
              <ExclamationCircleOutlined />
            </div>
            <div className='pb-2'>
              <IconSlider
                min={4}
                max={7}
                pointTeaser={pointTeaser}
                setPointTeaser={setPointTeaser}
              />
            </div>
            <div className='flex'>

            </div>
          </div>
          {
            listTeaserBetsSlip && listTeaserBetsSlip.map((item, i) => (
              <ItemTeaser
                key={i}
                index={i}
                item={item}
                listTeaserBetsSlip={listTeaserBetsSlip}
                setListTeaserBetsSlip={setListTeaserBetsSlip}
                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
              />
            ))
          }


          {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
          <div>
            <Checkbox
              checked={isAllCheckedTeaser}
              onChange={(e) => handleCheckAllTeaser(e.target.checked)}
            ></Checkbox>
          </div>
          <div className='flex'>
            <Button className='!flex items-center pl-2' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
          </div>
        </div> */}
          <BetSlipTab showTabs={showTabs} />
        </div>,
      },
      // {
      //   key: '4',
      //   label: 'If Bet',
      //   children: <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
      //     {arrayActiveItem.map((item, index) => (
      //       <div
      //         key={index}
      //       >
      //         <div className='flex justify-between pb-4 pt-4' key={`IfBet-${item}`}>
      //           <div>
      //             <Checkbox ></Checkbox>
      //             <span className='font-[500] text-black pl-2'>Brooklyn Nets +3.0</span>
      //           </div>
      //           <div className='flex'>
      //             <span className='pr-2 font-[500] text-black'>2.10</span>
      //             <CloseOutlined className='text-[#898989]' onClick={() => handleHiddenBox(item)} />
      //           </div>
      //         </div>
      //         <div className='flex justify-start pl-2 pb-4'>
      //           <span className='text-[#898989]'>Speard</span>
      //         </div>
      //         <div className='flex justify-start pl-2 betClippers'>
      //           <span className='font-[500] text-black'>Brooklyn Nets & Boston Celtics Clippers</span>
      //         </div>
      //       </div>
      //     ))}

      //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
      //       <Button className='flex items-center pl-0' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
      //       {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
      //     </div>
      //     <BetSlipTab showTabs={showTabs} />
      //   </div>,
      // },
      // {
      //   key: '5',
      //   label: 'Reverse',
      //   children: <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
      //     {arrayActiveItem.map((item, index) => (
      //       <div
      //         key={index}
      //       >
      //         <div className='flex justify-between pb-4 pt-4' key={`Reverse-${item}`}>
      //           <div>
      //             <Checkbox></Checkbox>
      //             <span className='font-[500] text-black pl-2'>Phoenix Suns +2.5</span>
      //           </div>
      //           <div className='flex'>
      //             <span className='pr-2 font-[500] text-black'>0.95</span>
      //             <CloseOutlined className='text-[#898989]' onClick={() => handleHiddenBox(item)} />
      //           </div>
      //         </div>
      //         <div className='flex justify-start pl-2 pb-4'>
      //           <span className='text-[#898989]'>Speard</span>
      //         </div>
      //         <div className='flex justify-start pl-6 betClippers'>
      //           <span className='font-[500] text-black'>Phoenix Suns & Brooklyn Nets Clippers</span>
      //         </div>
      //       </div>
      //     ))}


      //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
      //       <Button className='flex items-center pl-0' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
      //       {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
      //     </div>
      //     <BetSlipTab showTabs={showTabs} />
      //   </div>,
      // },
    ]) : ([
      {
        key: '1',
        label: settingbet.includes('1') ? 'Straight' : '',
        children:
          <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
            {
              listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                <ItemStraight
                  tab={tab}
                  key={i}
                  index={i}
                  item={item}
                  listAddStrightBetsSlip={listAddStrightBetsSlip}
                  rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                  setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                  setTotalStake={setTotalStake}
                  decimal={decimal}
                />
              ))
            }

            {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
              <Checkbox
                checked={isAllChecked}
                onChange={(e) => handleCheckAll(e.target.checked)}
              ></Checkbox>
              <div className='flex flex-row-reverse'>
                <Button
                  className='!flex items-center pl-2 pr-1'
                  type="text"
                  onClick={clearAll}
                >
                  <DeleteOutlined
                    className='text-[#898989]'
                  />
                  <span className='text-white font-[500]'
                  >Clear All
                  </span>
                </Button>
              </div>
            </div> */}
            <BetSlipTab showTabs={showTabs} />
          </div>,
      },
      // {
      //   key: '2',
      //   label: 'Parlay',
      //   children: <div className={`betTabs ? "hidden" : ""} ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
      //     {
      //       listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
      //         <ItemParlay
      //           key={i}
      //           index={i}
      //           item={item}
      //           listParlayBetsSlip={listParlayBetsSlip}
      //           rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
      //           setListParlayBetsSlip={setListParlayBetsSlip}
      //           decimal={decimal}
      //         // setTotalStake={setTotalStake}

      //         />
      //       ))}

      //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
      //       <Checkbox
      //         checked={isAllCheckedParlay}
      //         onChange={(e) => handleCheckAllParlay(e.target.checked)}
      //       ></Checkbox>
      //       <div className='flex flex-row-reverse'>
      //         <Button
      //           className='!flex items-center pl-2 pr-1'
      //           type="text"
      //           onClick={clearAll}
      //         >
      //           <DeleteOutlined
      //             className='text-[#898989]'
      //           />
      //           <span className='text-black font-[500]'
      //           >Clear All
      //           </span>
      //         </Button>
      //         {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
      //       </div>
      //     </div>
      //     <BetSlipTab showTabs={showTabs} />
      //   </div>,
      // },
    ])
  // const [items, setItems] = useState<TabsProps['items']>()
  // useEffect(() => {
  //   if (keyGameTab == "gameOnline") {
  //     setItems([
  //       {
  //         key: '1',
  //         label: 'Straight',
  //         children:
  //           <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
  //             {
  //               listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
  //                 <ItemStraight
  //                   key={i}
  //                   index={i}
  //                   item={item}
  //                   listAddStrightBetsSlip={listAddStrightBetsSlip}
  //                   rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
  //                   setListaddStrightBetsSlip={setListaddStrightBetsSlip}
  //                   setTotalStake={setTotalStake}

  //                 />
  //               ))
  //             }

  //             <div className='flex justify-between pt-3'>
  //               <Checkbox
  //                 checked={isAllChecked}
  //                 onChange={(e) => handleCheckAll(e.target.checked)}
  //               ></Checkbox>
  //               <div className='flex flex-row-reverse'>
  //                 <Button
  //                   className='!flex items-center pl-2 pr-1'
  //                   type="text"
  //                   onClick={clearAll}
  //                 >
  //                   <DeleteOutlined
  //                     className='text-[#898989]'
  //                   />
  //                   <span className='text-black font-[500]'
  //                   >Clear All
  //                   </span>
  //                 </Button>
  //                 <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} />
  //               </div>
  //             </div>
  //             <BetSlipTab showTabs={showTabs} />
  //           </div>,
  //       }])
  //   } else {
  //     setItems(itemsDefault)
  //   }
  // }, [keyGameTab])
  // console.log(items)
  return (
    <div>
      <Tabs items={items} activeKey={keyBetTabs} onChange={(e) => {
        if (e === '3') {
          if (listChooseBet && listChooseBet?.filter(item => item.lineBetting === "Money").length > 0) {
            pushToast(`Teaser does not allow Money Line betting`, "warn");
          } else {
            setKeyBetTabs(e)
          }
        } else {
          setKeyBetTabs(e)
        }
      }} className="tab-bet-home flex justify-start tab-bet " />
    </div>
  );
};

export default BetTabs;