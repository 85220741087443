/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
interface SoccerTopHDPFProps {
    homeTeam: string
    awayTeam: string
    item: InfoGameDetailSoccer[]
    oddsOld?: Odd[]
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTopOESideBet: React.FC<SoccerTopHDPFProps> = ({
    homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip,
    updateOdds
}) => {
    const odd = useRef<any[]>([item])
    const oddOld = useRef<any[]>([oddsOld])
    const updateArrayOdds = async () => {
        item.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemState, setItemState] = useState<any[]>([item]);
    useEffect(() => {
        item.forEach(itemDetail => {
            itemDetail.prices.sort((a, b) => {
                if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                return 0; // Giữ nguyên thứ tự nếu option không phải "h"
            });
        });
        if (oddsOld) {
            oddsOld.forEach(itemDetail => {
                itemDetail.prices.sort((a, b) => {
                    if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                    if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                    return 0; // Giữ nguyên thứ tự nếu option không phải "h"
                });
            });
        }
        setItemState(item)
        odd.current = item
        oddOld.current = oddsOld ? oddsOld : item
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])
    // console.log(listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id))
    return (
        <>
            <div className='w-1/4 flex flex-col justify-between a1x2'>
                <div className={`flex justify-between w-full h-[230px] items-center border-dashed border-b border-[#202531]`}>
                    <div className='w-full flex flex-col hdp'>
                        <div className={`justify-center w-full flex items-center `}>
                            {/* // OddEven */}
                            {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 0
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[1]?.prices?.[0]?.price !== oddOld.current?.[1]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === "Odd" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, "Odd " + itemState[1].prices[0].price, "Odd", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, homeTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        1, itemState[1].id, homeTeam, "Odd" + " "
                                    // + (itemState[1].point >= 0 ? itemState[1].point : "")
                                    + itemState[1].prices[0].price, "Odd"
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>O</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[1].prices[0].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center `}>
                            {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 1
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price,
                                    () => (listChooseBet && odd.current?.[1]?.prices?.[1]?.price !== oddOld.current?.[1]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === "Even" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, "Even " + itemState[1].prices[1].price, "Even", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, awayTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        1, itemState[1].id, awayTeam, "Even" + " "
                                    //  + (itemState[1].point < 0 ? itemState[1].point : "")
                                    + itemState[1].prices[1].price, "Even"
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>E</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[1].prices[1].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}>
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex justify-between w-full h-[230px] items-center mt-4 border-dashed border-b border-[#202531]`}>
                    <div className='w-full flex flex-col hdp'>
                        <div className={`justify-center w-full flex items-center `}>
                            {/* // OddEven */}
                            {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 0
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[2]?.prices?.[0]?.price !== oddOld.current?.[2]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === "Odd" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, "Odd " + itemState[2].prices[0].price, "Odd", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, homeTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        2, itemState[2].id, homeTeam, "Odd" + " "
                                    // + (itemState[2].point >= 0 ? itemState[2].point : "")
                                    + itemState[2].prices[0].price, "Odd"
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>O</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[2].prices[0].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center `}>
                            {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 1
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price,
                                    () => (listChooseBet && odd.current?.[2]?.prices?.[1]?.price !== oddOld.current?.[2]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === "Even" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, "Even " + itemState[2].prices[1].price, "Even", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, awayTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        2, itemState[2].id, awayTeam, "Even" + " "
                                    + itemState[2].prices[1].price, "Even"
                                        // + (itemState[2].point < 0 ? itemState[2].point : "")
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>E</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[2].prices[1].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}>
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex justify-between w-full h-[230px] items-center mt-4 `}>
                    <div className='w-full sflex flex-col hdp'>
                        <div className={`justify-center w-full flex items-center `}>
                            {/* // OddEven */}
                            {itemState && itemState.length > 3 && itemState[3].prices && itemState[3].prices.length > 0
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[3]?.prices?.[0]?.price !== oddOld.current?.[3]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === "Odd" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, "Odd " + itemState[3].prices[0].price, "Odd", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, homeTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[3].id, homeTeam, "Odd" + " "
                                    // + (itemState[0].point >= 0 ? itemState[0].point : "")
                                    + itemState[3].prices[0].price, "Odd"
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>O</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[3].prices[0].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>}
                        </div>
                        <div className={`justify-center w-full flex items-center `}>
                            {itemState && itemState.length > 3 && itemState[30].prices && itemState[3].prices.length > 1
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative 
                                    ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price,
                                    () => (listChooseBet && odd.current?.[3]?.prices?.[1]?.price !== oddOld.current?.[3]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === "Even" ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, "Even " + itemState[3].prices[1].price, "Even", 0, 0) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer,
                                )} 
                                    ${checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, awayTeam, "OddEven")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[3].id, awayTeam, "Even" + " "
                                    // + (itemState[0].point < 0 ? itemState[0].point : "")
                                    + itemState[3].prices[1].price, "Even"
                                    )}
                                >
                                    <p className='text-[#E4E7F1] text-[16px]'>E</p>
                                    <p><b className='text-[#E4E7F1] text-[18px]'>{itemState[3].prices[1].price.toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='text-[#E4E7F1]'><LockOutlined /></p>
                                </div>}
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}>
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default SoccerTopOESideBet;