/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd"
import { useEffect, useRef, useState } from "react";
import { InfoBill, InfoBillParlay, InfoBillTeaser } from "../../api/betting/bettingApi";
import './styles.css'
import { useSelector } from "react-redux";
import { selectorUser } from '../../store/slices/userSlice';
import { useReactToPrint } from "react-to-print";
// import QRCode from "react-qr-code";
import { QRCode } from 'react-qrcode-logo';
import { formatPrice } from "../../until";
import { ICON_GAMME } from "../../env";

declare global {
  interface Window {
    electron: {
      printContent: (content: any) => void;
    };
    electronAPI: {
      printComponent: (content: string, response: any) => void;
      previewComponent: (content: string, response: any) => void;

    }
  }
}
interface Props {
  showModalBill: boolean
  keyBetTabs?: string
  totalAmount: number,
  billCode?: string
  listBill: InfoBill[]
  listBillParlay?: InfoBillParlay
  listBillTeaser?: InfoBillTeaser
  decimal?: string
  setShowModalBill: (value: boolean) => void
}

function formatDate(UTC: string) {
  let d = new Date(UTC).toLocaleDateString()
  return d
}

function formatTime(UTC: string) {
  let d = new Date(UTC).toLocaleTimeString()
  return d
}


function formatDateNow() {
  let d = new Date().toLocaleDateString()
  return d
}

// function formatTimeNow() {
//     let d = new Date().toLocaleTimeString()
//     console.log((new Date()).getHours())
//     return d
// }

function formatTimeNow() {
  let date = new Date()
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let period = hours >= 12 ? 'PM' : 'AM';

  // Chuyển đổi sang định dạng 12 giờ
  hours = hours % 12 || 12;

  return hours.toString().padStart(2, "0") + ':' + minutes.toString().padStart(2, "0") + ' ' + period;
}

function formatDateWithDate(date: any) {
  let d = new Date(date).toLocaleDateString()
  return d
}

function formatTimeWithDate(day: any) {
  let date = new Date(day)
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let period = hours >= 12 ? 'PM' : 'AM';

  // Chuyển đổi sang định dạng 12 giờ
  hours = hours % 12 || 12;

  return hours.toString().padStart(2, "0") + ':' + minutes.toString().padStart(2, "0") + ' ' + period;
}


const ModalBill: React.FC<Props> = ({
  showModalBill, keyBetTabs, totalAmount, billCode, listBill, listBillParlay, listBillTeaser, decimal,
  setShowModalBill
}) => {
  const handlePrint2 = function (target: any) {
    return new Promise(() => {
      console.log("forwarding print request to the main process...");
      var text: any
      if (listBill.length > 0) {
        text = `
    <style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    box-sizing: border-box;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
  }
</style>

 <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>

 <script>
 var qrcode = document.getElementsByClassName("qrcode");
 for (let i = 0; i < qrcode.length; i++) {
    var qrcode123 = new QRCode(document.getElementById(qrcode[i].id), {
      text: qrcode[i].getAttribute("data-value"),
      width: 128,
      height: 128,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H
    });
 }
 
    </script>`
      } else {
        text = `
            <style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    box-sizing: border-box;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
  }
</style>
 <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>

 <script>
 var qrcode = document.getElementsByClassName("qrcode");
 
        var qrcode = new QRCode(document.getElementById("qrcode-parlay"), {
         text: "${billCode}",
         width: 128,
         height: 128,
     });
    </script>`
      }

      // ${
      //                 listBill.map((item, i) => (`
      //         var qrcode-${i} = new QRCode(document.getElementById("qrcode-0"), {
      //          text: "123",
      //          width: 128,
      //          height: 128,
      //      });`

      //                 ))
      //             }
      const data = target.contentWindow.document.documentElement.outerHTML;
      const blob = new Blob([data + text], { type: "text/html" });


      const url = URL.createObjectURL(blob);
      // console.log(url);


      window.electronAPI.printComponent(url, (response: any) => {
        console.log("Main: ", response);
      });

      //console.log('Main: ', data);
    });
  };
  const handleBillPrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Bill component",
    print: handlePrint2,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("sau khi in"),
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userId, presentMoney, authCode, machineCode } = useSelector(selectorUser);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [keytab, setKeytab] = useState('STRAIGHT')


  const checkKeyTab = () => {
    if (listBill.length > 0) {
      setKeytab('STRAIGHT')
    } else if (listBillParlay && listBillParlay.data.length > 0) {
      setKeytab('PARLAY')
    } else if (listBillTeaser && listBillTeaser.data.length > 0) {
      setKeytab('TEASER')
    }
  }
  useEffect(() => {
    checkKeyTab()
  }, [])


  const contentToPrint = useRef(null);
  // const handlePrintPos = useReactToPrint({
  //     // content: () => contentToPrint.current,
  //     // removeAfterPrint: true,
  // });

  // const handlePrint = useReactToPrint({
  //   documentTitle: "Print This Document",
  //   print: async (printIframe: HTMLIFrameElement) => {
  //     const document = printIframe.contentDocument;

  //     if (document) {
  //       window.electron.printContent(document)
  //     }
  //   },
  //   onBeforePrint: () => console.log("before printing..."),
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true,
  // });



  useEffect(() => {
    if (showModalBill) {
      setTimeout(() => {
        handleBillPrint()
      }, 500)
      setTimeout(() => {
        setShowModalBill(false);
      }, 5000)
    }

  }, [showModalBill]);

  return (

    <Modal
      className='billRefund flex justify-center'
      title={null}
      open={showModalBill}
      onOk={() => setShowModalBill(false)}
      onCancel={() => setShowModalBill(false)}
      footer={null}
      closable={false}
    >
      {/* <div ref={contentToPrint}>Hello Again</div> */}
      {/* <div className="flex justify-end mb-3">
        <button className="bg-[#BB271A] text-white rounded px-1 font-bold" onClick={() => {
          // handleShare();
          handleBillPrint()

        }}>
          Print
        </button>
      </div> */}
      <div className=" text-sm max-h-[70vh] overflow-y-auto" >
        {keyBetTabs !== "1" && (
          <div>
            <div className='flex justify-between items-center' style={{ display: 'flex' }}>
              <div className="w-[30%]"><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack222" /></div>
              <div><span className='font-bold text-[18px]'>KINGBETS</span></div>
              <div className="w-[30%]"></div>
            </div>
            <div className="px-2 text-[#000000] text-right text-[12px]">
              <span>{formatDateNow()} | {formatTimeNow()} </span>
              {/* <span>{listBill.length ? formatDateWithDate(listBill[0].date) : formatDateNow()} | {listBill.length ? formatTimeWithDate(listBill[0].date) : formatTimeNow()} </span> */}

            </div>
            <div className="w-full overflow-hidden">
              <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
            </div>
            <div>
              <p className='px-2 font-bold text-[#000000]'>Bill Code: {billCode}</p>
              <p className='px-2 font-bold text-[#000000]'>Machine Code: {machineCode}</p>
            </div>
            <div className="w-full overflow-hidden">
              <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
            </div>
          </div>
        )}

        <div>
          {keyBetTabs !== "1" && keyBetTabs !== "3" && (
            <>
              <div>
                <p className="px-2 ">{keyBetTabs === "1" ? "STRAIGHT" : (keyBetTabs === "2" ? "PARLAY" : "TEASER")} BET | {keyBetTabs === "1" ? listBill.length : (keyBetTabs === "2" ? listBillParlay?.data.length : (listBillTeaser?.data.length))} BET </p>
              </div>
              <div className="w-full overflow-hidden">
                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
              </div>
            </>
          )}
          {
            listBill && listBill?.map((item, i) =>
              <>
                <div className="">
                  <div>
                    <div className='flex justify-between items-center'>
                      <div className="w-[30%]"><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack" /></div>
                      <div><span className='font-bold text-[18px]'>KINGBETS</span></div>
                      <div className="w-[30%]"></div>
                    </div>
                    <div className="pr-2 text-[#000000] text-right text-[12px]">
                      {/* <span>{formatDateNow()} | {formatTimeNow()} </span> */}
                      <span>{listBill.length ? formatDateWithDate(listBill[0].date) : formatDateNow()} | {listBill.length ? formatTimeWithDate(listBill[0].date) : formatTimeNow()} </span>
                    </div>
                    <div className="w-full overflow-hidden">
                      <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                    </div>
                    <div>
                      <p className='px-2 font-bold text-[#000000]' >Bill Code: {item.billCode}</p>
                      <p className='px-2 font-bold text-[#000000]'>Machine Code: {machineCode}</p>
                    </div>
                    <div className="w-full overflow-hidden">
                      <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                    </div>
                  </div>
                  <div>
                    <p className="px-2 ">STRAIGHT BET </p>
                    <p className="px-2 ">1 BET (S) @ {Number(item.amount).toFixed(0)}</p>
                  </div>
                  <div className="w-full overflow-hidden">
                    <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                  </div>
                  <div key={i}>

                    <div className="px-2 flex justify-between">
                      {/* <img src={NBABlack} alt="NBA" /> */}
                      {/* <p className='p-2'>{item.tournament}: {item.lineBetting}</p> */}
                      {item?.live ? (
                        <p className="">{formatDate(item.date) + " " + formatTime(item.date)}</p>
                      ) :
                        <p className="">{item.dateEvent}</p>
                      }


                      <p className=''>{item.tournament}: {item.lineBetting}</p>
                    </div>
                    <p className='px-2 font-bold'>{item.game}</p>
                    <div className="flex justify-between">
                      <p className='px-2'>{item.teamChoose}</p>
                      <p className='px-2 whitespace-nowrap'>{item.scoreChoose}</p>
                    </div>
                    {item?.live && item?.homeLiveScore !== '' && item?.awayLiveScore !== '' ? (
                      <div className="flex">
                        <p className='px-2 font-bold'>{(item?.homeLiveScore ? item?.homeLiveScore : "0") + " : " + (item?.awayLiveScore ? item?.awayLiveScore : "0")}</p>
                      </div>
                    ) :
                      ""
                    }
                    {item?.live ? (
                      <div className="flex">
                        <p className='px-2 font-bold'>Live</p>
                      </div>
                    ) :
                      ""
                    }

                    {/* <p className='p-2'>{item.lineBetting}</p> */}
                    <div className="w-full overflow-hidden">
                      <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                    </div>
                    <div className="pt-2">
                      <div className="flex justify-between">
                        <p className='px-2 font-bold text-[#000000]'>Total Stake</p>
                        <p className='px-2 font-bold text-[#000000]'>{"$" + formatPrice(item.amount)}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className='px-2 font-bold text-[#000000]'>Potential payout</p>
                        <p className='px-2 font-bold text-[#000000]'>{"$" + formatPrice(Number(item.win).toFixed(0))}</p>
                      </div>
                    </div>
                    <div className="w-full overflow-hidden">
                      <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                    </div>
                  </div>
                  <div className={`flex flex-col items-center py-2`}>
                    {/* <img className='h-[120px] w-[120px]' src={QRcode} alt="QRcode" /> */}
                    <QRCode
                      size={120}
                      value={item.billCode ? item.billCode : ""}
                      // logoImage={logoBill}
                      logoWidth={30}
                      logoHeight={30}
                    />
                    {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
                  </div>
                </div>
              </>
            )
          }
          {
            listBillParlay?.data && listBillParlay?.data.map((item, i) => (
              <div key={i}>

                <div className="px-2 pl-2 flex justify-between">
                  {/* <img src={NBABlack} alt="NBA" /> */}
                  <p className="">{formatDate(item.date)}</p>
                  <p className=''>{item.tournament}: {item.lineBetting}</p>
                </div>
                <p className='px-2 font-bold'>{item.game}</p>
                <div className="flex justify-between">
                  <p className='px-2'>{item.teamChoose}</p>
                  <p className='px-2 whitespace-nowrap'>{item.scoreChoose}</p>
                </div>
                {item?.live && item?.homeLiveScore !== '' && item?.awayLiveScore !== '' ? (
                  <div className="flex">
                    <p className='px-2 font-bold'>{(item?.homeLiveScore ? item?.homeLiveScore : "0") + " : " + (item?.awayLiveScore ? item?.awayLiveScore : "0")}</p>
                  </div>
                ) :
                  ""
                }
                {item?.live ? (
                  <div className="flex">
                    <p className='px-2 font-bold'>Live</p>
                  </div>
                ) :
                  ""
                }
                {/* <p className='px-2'>{item.lineBetting}</p> */}
                <div className="w-full overflow-hidden">
                  <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                </div>
              </div>
            ))
          }
          {listBillTeaser?.data && listBillTeaser?.data.length > 0 && (
            <>
              <p className="px-2 ">TEASER BET | {listBillTeaser?.data.length} BET </p>
              <div className="w-full overflow-hidden">
                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
              </div>
            </>
          )}
          {
            listBillTeaser?.data && listBillTeaser?.data.map((item, i) => (
              <div key={i}>

                <div className="px-2 pl-2 flex justify-between">
                  <p className="">{formatDate(item.date)}</p>
                  <p className='px-2'>{item.tournament}: {item.lineBetting}</p>
                </div>
                <p className='px-2 font-bold'>{item.game}</p>
                <div className="px-2 pl-2 flex justify-between">
                  <p className=''>{item.teamChoose}</p>
                  <p className='px-2'>{item.scoreChoose}</p>
                </div>
                <p className='px-2 font-bold'>Points: {listBillTeaser.point}</p>
                <p className='px-2 font-bold'>New lines: {item.newLine}</p>
                {/* <p className='px-2'>{item.lineBetting}</p> */}
                <div className="w-full overflow-hidden">
                  <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                </div>
              </div>
            ))
          }
          <div className={`flex flex-col ${(listBillParlay?.totalOdds || listBillTeaser?.totalOdds) ? "" : "hidden"}`}

          >
            {/* <p
                            className='px-2 text-[#898989]'
                        >
                            <b>Total odds / Total stake:</b> {(listBillParlay?.totalOdds || listBillTeaser?.totalOdds) + "  / " + (listBillParlay?.totalStake || listBillTeaser?.totalStake) + " $"}
                        </p> */}
            <div className="pt-2">
              <div className="flex justify-between">
                <p className='px-2 font-bold text-[#000000]'>Total Odds</p>
                <p className='px-2 font-bold text-[#000000]'>{(listBillParlay?.totalOdds || listBillTeaser?.totalOdds)}</p>
              </div>
              <div className="flex justify-between">
                <p className='px-2 font-bold text-[#000000]'>Total Stake</p>
                <p className='px-2 font-bold text-[#000000]'>${(listBillParlay?.totalStake || listBillTeaser?.totalStake)}</p>
              </div>
              <div className="flex justify-between">
                <p className='px-2 font-bold text-[#000000]'>Potential payout</p>
                <p className='px-2 font-bold text-[#000000]'>
                  {
                    (listBillParlay && listBillParlay.totalOdds && listBillParlay.totalStake) ?
                      "$" + formatPrice(((listBillParlay.totalOdds || 0) * (listBillParlay.totalStake || 0)).toFixed(0)) :
                      (listBillTeaser && listBillTeaser.totalOdds && listBillTeaser.totalStake) ?
                        "$" + formatPrice(((listBillTeaser.totalOdds || 0) * (listBillTeaser.totalStake || 0)).toFixed(0)) : ""
                  }
                </p>
              </div>
            </div>
          </div>


          {/* <p className='p-2 font-bold'>Total Goals</p>
                    <p className='p-2 font-bold'>Under 1.5 ( -222)</p>
                    <p className='p-2 font-bold'>Premier L</p>
                    <p className='p-2 font-bold'>Aston Villa</p>
                    <p className='p-2 font-bold'>LIVE@1.0</p>
                    <p className='p-2 font-bold'>Single</p>
                    <div className="w-full overflow-hidden">
                                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                            </div>
                    <div className="w-full overflow-hidden">
                                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                            </div> */}
        </div>
        {/* <div id="qrcode"></div> */}
        <div className={`flex flex-col items-center pt-4 ${(listBillParlay?.totalOdds || listBillTeaser?.totalOdds) ? "" : "hidden"}`}>
          {/* <img className='h-[120px] w-[120px]' src={QRcode} alt="QRcode" /> */}
          <QRCode
            size={120}
            value={billCode ? billCode : ""}
            // logoImage={logoBill}
            logoWidth={30}
            logoHeight={30}
          />
          {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
        </div>
      </div>
      <div className="hidden">
        <div ref={contentToPrint} style={{ width: '100%', fontSize: '14px', paddingRight: '45px' }}>
          {keyBetTabs !== "1" && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '50px' }}><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack222" style={{ width: '100%' }} /></div>
                <div><span style={{ fontWeight: '700', fontSize: '18px' }}>KINGBETS</span></div>
                <div style={{ width: '50px' }}></div>
              </div>
              <div style={{ paddingLeft: '8px', paddingRight: '8px', color: 'black', textAlign: 'right', fontSize: '12px' }}>
                <span>{formatDateNow()} | {formatTimeNow()} </span>
                {/* <span>{listBill.length ? formatDateWithDate(listBill[0].date) : formatDateNow()} | {listBill.length ? formatTimeWithDate(listBill[0].date) : formatTimeNow()} </span> */}
              </div>
              <div style={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }} >----------------------------------------------------------------------------------</div>
              </div>
              <div>
                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Bill Code: {billCode}</p>
                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Machine Code: {machineCode}</p>
              </div>
              <div style={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
              </div>
            </div>
          )}

          <div>
            {keyBetTabs !== "1" && keyBetTabs !== "3" && (
              <>
                <div>
                  <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{keyBetTabs === "1" ? "STRAIGHT" : (keyBetTabs === "2" ? "PARLAY" : "TEASER")} BET | {keyBetTabs === "1" ? listBill.length : (keyBetTabs === "2" ? listBillParlay?.data.length : (listBillTeaser?.data.length))} BET </p>
                </div>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                </div>
              </>
            )}
            {
              listBill.length > 0 && listBill?.map((item, i) =>

                <div style={{ pageBreakAfter: i === listBill.length - 1 ? "unset" : 'always' }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ width: '50px' }}><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack" style={{ width: '100%' }} /></div>
                      <div><span style={{ fontWeight: '700', fontSize: '18px' }}>KINGBETS</span></div>
                      <div style={{ width: '50px' }}></div>
                    </div>
                    <div style={{ paddingRight: '8px', color: 'black', textAlign: 'right', fontSize: '12px' }}>
                      {/* <span style={{ margin: 0 }}>{formatDateNow()} | {formatTimeNow()} </span> */}
                      <span style={{ margin: 0 }}>{listBill.length ? formatDateWithDate(listBill[0].date) : formatDateNow()} | {listBill.length ? formatTimeWithDate(listBill[0].date) : formatTimeNow()} </span>

                    </div>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                    </div>
                    <div>
                      <p className='bill-code' style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }} data-id={item.billCode}>Bill Code: {item.billCode}</p>
                      <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Machine Code: {machineCode}</p>
                    </div>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                    </div>
                  </div>
                  <div>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>STRAIGHT BET </p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }} >1 BET (S) @ {Number(item.amount).toFixed(0)}</p>
                  </div>
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                  </div>
                  <div key={i}>

                    <div style={{ paddingLeft: '8px', paddingRight: '8px', display: 'flex', justifyContent: 'space-between' }}>
                      {/* <img src={NBABlack} alt="NBA" /> */}
                      {/* <p className='p-2'>{item.tournament}: {item.lineBetting}</p> */}
                      {item?.live ? (
                        <p className="" style={{ margin: 0 }}>{formatDate(item.date) + " " + formatTime(item.date)}</p>
                      ) :
                        <p className="" style={{ margin: 0 }}>{item.dateEvent}</p>
                      }


                      <p className=''>{item.tournament}: {item.lineBetting}</p>
                    </div>
                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.teamChoose}</p>
                      <p style={{ paddingLeft: '8px', paddingRight: '8px', whiteSpace: 'nowrap' }}>{item.scoreChoose}</p>
                    </div>
                    {item?.live && item?.homeLiveScore !== '' && item?.awayLiveScore !== '' ? (
                      <div style={{ display: 'flex' }}>
                        <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: 900 }}>{(item?.homeLiveScore ? item?.homeLiveScore : "0") + " : " + (item?.awayLiveScore ? item?.awayLiveScore : "0")}</p>
                      </div>
                    ) :
                      ""
                    }
                    {item?.live ? (
                      <div style={{ display: 'flex' }}>
                        <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: 900 }}>Live</p>
                      </div>
                    ) :
                      ""
                    }

                    {/* <p className='p-2'>{item.lineBetting}</p> */}
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                    </div>
                    <div style={{ paddingTop: '8px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total Stake</p>
                        <p style={{ margin: 0, paddingRight: '8px', paddingLeft: '8px', fontWeight: '700', color: 'black' }}>{"$" + formatPrice(item.amount)}</p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Potential payout</p>
                        <p style={{ margin: 0, paddingRight: '8px', paddingLeft: '8px', fontWeight: '700', color: 'black' }}>{"$" + formatPrice(item.win)}</p>
                      </div>
                    </div>

                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0px 8px 0px' }}>
                    {/* <img className='h-[120px] w-[120px]' src={QRcode} alt="QRcode" /> */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                      <div className={`qrcode`} data-value={item.billCode} id={`qrcode-${i}`}></div>
                    </div>
                    {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
                  </div>
                </div>
              )
            }
            {
              listBillParlay?.data && listBillParlay?.data.map((item, i) => (
                <div key={i}>
                  <div style={{ padding: '0px 8px 0px 8px', display: 'flex', justifyContent: 'space-between' }}>
                    {/* <img src={NBABlack} alt="NBA" /> */}
                    <p className="" style={{ margin: 0 }}>{formatDate(item.date)}</p>
                    <p className='' style={{ margin: 0 }}>{item.tournament}: {item.lineBetting}</p>
                  </div>
                  <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.teamChoose}</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', whiteSpace: 'nowrap' }}>{item.scoreChoose}</p>
                  </div>
                  {item?.live && item?.homeLiveScore !== '' && item?.awayLiveScore !== '' ? (
                    <div style={{ display: 'flex' }}>
                      <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: 900 }}>{(item?.homeLiveScore ? item?.homeLiveScore : "0") + " : " + (item?.awayLiveScore ? item?.awayLiveScore : "0")}</p>
                    </div>
                  ) :
                    ""
                  }
                  {item?.live ? (
                    <div style={{ display: 'flex' }}>
                      <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: 900 }}>Live</p>
                    </div>
                  ) :
                    ""
                  }
                  {/* <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.lineBetting}</p> */}
                  <div style={{ margin: 0, width: '100%', overflow: 'hidden' }}>
                    <div style={{ margin: 0, whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                  </div>
                </div>
              ))
            }
            {listBillTeaser?.data && listBillTeaser?.data.length > 0 && (
              <>
                <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }} >TEASER BET | {listBillTeaser?.data.length} BET </p>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                </div>
              </>
            )}
            {
              listBillTeaser?.data && listBillTeaser?.data.map((item, i) => (
                <div key={i}>

                  {/* <div style={{ padding: '0px 8px 0px 8px', display: 'flex', justifyContent: 'space-between' }}>
                    <p className="" style={{ margin: 0 }}>{formatDate(item.date)}</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.tournament}: {item.lineBetting}</p>
                  </div>
                  <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                  <div className="flex">
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.teamChoose}</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.scoreChoose}</p>
                  </div>
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>-------------------------------------------------------</div>
                  </div> */}
                  <div style={{ margin: 0, padding: '0px 8px 0px 8px', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0, }}>{formatDate(item.date)}</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.tournament}: {item.lineBetting}</p>
                  </div>
                  <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                  <div style={{ margin: 0, padding: '0px 8px 0px 8px', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0 }}>{item.teamChoose}</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px' }}>{item.scoreChoose}</p>
                  </div>
                  <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>Points: {listBillTeaser.point}</p>
                  <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>New lines: {item.newLine}</p>
                  {/* <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.lineBetting}</p> */}
                  {/* <div style={{ paddingTop: '8px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total Stake</p>
                      <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>${(listBillTeaser?.totalOdds || listBillTeaser?.totalOdds)}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Potential payout</p>
                      <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>{
                        (listBillParlay && listBillParlay.totalOdds && listBillParlay.totalStake) ?
                          "$" + formatPrice(((listBillParlay.totalOdds || 0) * (listBillParlay.totalStake || 0)).toFixed(2)) :
                          (listBillTeaser && listBillTeaser.totalOdds && listBillTeaser.totalStake) ?
                            "$" + formatPrice(((listBillTeaser.totalOdds || 0) * (listBillTeaser.totalStake || 0)).toFixed(2)) : ""
                      }</p>
                    </div>
                  </div> */}
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>----------------------------------------------------------------------------------</div>
                  </div>
                </div>
              ))
            }
            {
              (listBillParlay?.totalOdds || listBillTeaser?.totalOdds) && <div
                style={{ display: (listBillParlay?.totalOdds || listBillTeaser?.totalOdds) ? "flex" : "none", flexDirection: 'column', }}
              >
                <div style={{ paddingTop: '8px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total Odds</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>{(listBillParlay?.totalOdds || listBillTeaser?.totalOdds)}</p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total Stake</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>${(listBillParlay?.totalStake || listBillTeaser?.totalStake)}</p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Potential payout</p>
                    <p style={{ margin: 0, paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>
                      {
                        (listBillParlay && listBillParlay.totalOdds && listBillParlay.totalStake) ?
                          "$" + formatPrice(((listBillParlay.totalOdds || 0) * (listBillParlay.totalStake || 0)).toFixed(0)) :
                          (listBillTeaser && listBillTeaser.totalOdds && listBillTeaser.totalStake) ?
                            "$" + formatPrice(((listBillTeaser.totalOdds || 0) * (listBillTeaser.totalStake || 0)).toFixed(0)) : ""
                      }
                    </p>
                  </div>
                </div>
              </div>
            }



            {/* <p className='p-2 font-bold'>Total Goals</p>
                    <p className='p-2 font-bold'>Under 1.5 ( -222)</p>
                    <p className='p-2 font-bold'>Premier L</p>
                    <p className='p-2 font-bold'>Aston Villa</p>
                    <p className='p-2 font-bold'>LIVE@1.0</p>
                    <p className='p-2 font-bold'>Single</p>
                    <div className="w-full overflow-hidden">
                                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                            </div>
                    <div className="w-full overflow-hidden">
                                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                            </div> */}
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <div id="qrcode-parlay"></div>
          </div>
        </div>
      </div>
    </Modal >
  )
}
export default ModalBill

