/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from "react";
import { InfoAddStrightSoccerTmp } from "../../../../../../api/betting/bettingApi";
import CaculateV2 from "../../../TotalStake/CaculateV2";
import { getPiceWinStright } from "../../../../../../until";
import { ElipseRed, soccer_2, Trash } from "../../../../../../components/imgExport";
interface Props {
    index: number
    item: InfoAddStrightSoccerTmp
    listAddStrightBetsSlip: InfoAddStrightSoccerTmp[]

    rStrightBetsSlipByIndex: (value: number) => void
    setListaddStrightBetsSlip: (value: InfoAddStrightSoccerTmp[]) => void
    setTotalStake: (index: number) => void

}
const ItemStraightSoccer: React.FC<Props> = ({
    index, item, listAddStrightBetsSlip,
    rStrightBetsSlipByIndex, setListaddStrightBetsSlip, setTotalStake
}) => {
    const [showStake, setShowStake] = useState(false);

    const handleClick = (e: number) => {
        if (item.amount) {
            // setTotalStake(totalStake + e)
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: (item.amount + e), win: getPiceWinStright(item.amount + e, item.scoreChoose) } : item));
            setListaddStrightBetsSlip(arr)
        } else {
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: getPiceWinStright(e, item.scoreChoose) } : item));
            setListaddStrightBetsSlip(arr)
        }
        setTotalStake(0)
    }
    const handleNumberClick = (e: number) => {
        if (item.amount) {
            // setTotalStake(totalStake + e)
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: (Number(`${item.amount}${e}`)), win: getPiceWinStright(Number(`${item.amount}${e}`), item.scoreChoose) } : item));
            setListaddStrightBetsSlip(arr)
        } else {
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: getPiceWinStright(e, item.scoreChoose) } : item));
            setListaddStrightBetsSlip(arr)
        }
        setTotalStake(0)
    }
    const handleDeleteClick = (e: number) => {
        var arr: any = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: "0" } : item));
        setListaddStrightBetsSlip(arr)
        setTotalStake(0)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheck = (e: boolean) => {
        var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, isCheck: e } : item));
        setListaddStrightBetsSlip(arr)
    }
    return (
        <div className="pl-2">
            <div className="w-full flex justify-center pb-4" key={`game-${item}`}>
                <span className='text-base text-white text-center'>{item.game.replace("/", "-vs-")}</span>
            </div>
            <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col gap-2">
                <div className='flex justify-between' key={`Straight-${item}`}>
                    <div className="flex gap-2">
                        <div className="flex items-center gap-2">
                            <img src={soccer_2} alt="" />
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center gap-2">
                                {/* <Checkbox
                        checked={item.isCheck}
                        onChange={(e) => handleCheck(!item.isCheck)}
                    ></Checkbox> */}
                                {/* <img className="pl-2" src={LiveDotted} alt="LiveDotted" /> */}

                                <span className='text-base text-white'>
                                    Soccer
                                </span>

                            </div>
                            <div className="flex items-center gap-2">
                                <span className='text-base text-white'>
                                    {item.lineBetting}
                                </span>
                            </div>
                        </div>
                    </div>


                    <div className='flex'>
                        <img src={Trash} alt=""
                            onClick={() => rStrightBetsSlipByIndex?.(index)}
                        />
                    </div>
                </div>
                <div className="w-fit justify-start flex bg-[#1E212B] ">
                    <span className='text-white text-left text-base px-2 py-[2px]'>{item.tournament}</span>
                </div>
                <div className='!flex justify-start'>
                    <span className='text-base text-white text-left'>{item.teamChoose}</span>
                </div>
                <div className='!flex justify-start'>
                    {item.scoreChoose.split(' ').length <= 2 &&
                        <span className='text-white text-base pr-1'>{item.scoreChoose.split(' ')[0] === 'undefined' ? "" : isNaN(Number(item.scoreChoose.split(' ')[0])) ? item.scoreChoose.split(' ')[0] : Number(item.scoreChoose.split(' ')[0]).toFixed(2)}</span>
                    }
                    {item.scoreChoose.split(' ').length > 2 &&
                        <span className='text-white text-base pr-1'>
                            {item.scoreChoose.split(' ').length > 0 && item.scoreChoose.split(' ').map((element, index) => (
                                <span>{(index !== item.scoreChoose.split(' ').length - 1) ? (item.scoreChoose.split(' ')[index] + " ") : ""}</span>
                            ))}
                        </span>
                    }
                    {/* <span className='text-white p-1'> <b>{isNaN(Number(item.scoreChoose.split(' ')[0]))}</b></span> */}
                    {item.scoreChoose.split(' ').length <= 2 && item.scoreChoose.split(' ')[1] ? (
                        <span className='text-white text-base'>@ {item.scoreChoose.split(' ')[1] ? Number(item.scoreChoose.split(' ')[1]).toFixed(2) : Number(item.scoreChoose.split(' ')[0]).toFixed(2)}</span>
                    ) : ""}
                    {item.scoreChoose.split(' ').length > 2 ? (
                        <span className='text-white text-base'>  {Number(item.scoreChoose.split(' ')[item.scoreChoose.split(' ').length - 1]).toFixed(2)}</span>
                    ) : ""}

                </div>
                {item?.live ? (
                    <span className='flex'>
                        <span className='flex w-fit bg-[#ffcccc] px-2 rounded-md text-red-500'>
                            <img className="pr-2" src={ElipseRed} alt="ElipseRed" /> LIVE
                        </span>
                    </span>
                ) : ''}
                <div
                    className='flex justify-between'
                >
                    <div
                        className={`flex justify-start ${listAddStrightBetsSlip.length > 1 ? "" : "hidden"}`}
                        onClick={() => setShowStake(!showStake)}
                    >
                        <span className=' rounded-lg font-bold text-[14px] h-[32px] stakeDetail text-white border-[1px] border-white pr-[10px] pl-[10px] pt-[4px] pb-[4px]' >
                            {item.amount === 0 ? '' : (
                                <span className="">
                                    {/* text-[#666E97] */}
                                    $
                                </span>
                            )}
                            {item.amount === 0 ? 'Total Stake' : `${item?.amount} `}
                        </span>
                    </div>
                    <div className={`flex flex-col justify-end ${listAddStrightBetsSlip.length > 1 ? "" : "hidden"}`}>
                        <span className="flex pt-3 text-[#13px]">
                            <p className="text-[#33E49A]">Win</p>
                            <b className="pl-1 text-[#33E49A]">{item.win === 0 ? '$-.--' : `$${item?.win}`}</b>
                        </span>
                    </div>
                </div>
            </div>

            <div className={`${listAddStrightBetsSlip.length > 1 ? "" : "hidden"}`}>
                <CaculateV2
                    showTotalStake={showStake}
                    handleNumberClick={handleNumberClick}
                    handleClick={handleClick}
                    handleDeleteClick={handleDeleteClick}
                    handleSuccess={() => setShowStake(false)}
                />
            </div>
        </div>
    )
}

export default ItemStraightSoccer;