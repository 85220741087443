import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { logoKB_2, search } from '../../../../../components/imgExport';
import '../../stylesMainNavi.css'
const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Sports',
  },
  {
    key: '2',
    label: 'Live in Game',
  },
];

const tabMargin = 30;
const tabBarGutter = (items.length - 1) * tabMargin;

const HeadTabs: React.FC = () => (
  <div className="flex items-center">
    <img src={logoKB_2} alt="Logo" className='w-[80px] h-[43px]' onClick={() => { window.location.reload() }} />
    <div className='hidden w-[173px] mr-[28px] ml-[28px]'>

      <Tabs
        className="headTab"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        tabBarGutter={tabBarGutter}
      />
      {/*  */}
    </div>
    <div className='hidden'>
      <img src={search} alt="Search" />
    </div>

  </div>
);

export default HeadTabs;
