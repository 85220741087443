/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'antd';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerMoreTabNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { CaretRightOutlined } from '@ant-design/icons';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
interface Props {
    totalGame?: number
    oddsEven: any[]
    totalGoal: any[]
    doubleChance: any[]
    oddsEvenOld?: Odd[]
    totalGoalOld?: Odd[]
    doubleChanceOld?: Odd[]
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    chooseBetTopDoubleChance?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    chooseBetSoccerOEF?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const StatusBarSoccerMoreTab: React.FC<Props> = ({
    oddsEven, totalGoal, doubleChance, oddsEvenOld, totalGoalOld, doubleChanceOld, totalGame, homeTeam, awayTeam, listChooseBet, listAddStrightBetsSlipSoccer, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, chooseBetTopDoubleChance, chooseBetSoccerOEF, checkUpdateStrightBetsSlip, updateOdds }) => {
    const odd = useRef<any[]>([totalGoal])
    const oddOld = useRef<any[]>([totalGoalOld])
    const oddDoubleChance = useRef<any[]>([doubleChance])
    const oddDoubleChanceOld = useRef<any[]>([doubleChanceOld])
    const oddEven = useRef<any[]>([oddsEven])
    const oddEvenOld = useRef<any[]>([oddsEvenOld])


    const updateArrayOdds = async () => {
        totalGoal.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        doubleChance.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = oddDoubleChance.current
                    setTimeout(() => {
                        const abc: any[] = oddDoubleChance.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        oddDoubleChance.current = abc
                        oddDoubleChanceOld.current = a
                    }, 500);
                }
            });
        });
        oddsEven?.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = oddEven.current
                    setTimeout(() => {
                        const abc: any[] = oddEven.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        oddEven.current = abc
                        oddEvenOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setItemDoubleChanceState(oddDoubleChance.current);
        setItemOddEvenState(oddEven.current);
        setTimeout(() => {
            oddEvenOld.current = oddEven.current
            oddDoubleChanceOld.current = oddDoubleChance.current
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemOddEvenState, setItemOddEvenState] = useState<any[]>([oddEven]);
    const [itemState, setItemState] = useState<any[]>([totalGoal]);
    // const [itemStateOld, setItemStateOld] = useState<any[]>([totalGoal]);
    const [itemDoubleChanceState, setItemDoubleChanceState] = useState<any[]>([doubleChance]);
    // const [itemDoubleChanceStateOld, setItemDoubleChanceStateOld] = useState<any[]>([doubleChance]);
    useEffect(() => {
        setItemOddEvenState(oddsEven)
        oddEven.current = oddsEven
        oddEvenOld.current = oddsEvenOld ? oddsEvenOld : oddsEven
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oddEven, totalGoal, doubleChance])

    useEffect(() => {
        setItemState(totalGoal)
        odd.current = totalGoal
        oddOld.current = totalGoalOld ? totalGoalOld : totalGoal
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalGoal])

    useEffect(() => {
        setItemDoubleChanceState(doubleChance)
        oddDoubleChance.current = doubleChance
        oddDoubleChanceOld.current = doubleChanceOld ? doubleChanceOld : doubleChance
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doubleChance])

    // useEffect(() => {
    //     setTimeout(() => {
    //         oddDoubleChanceOld.current = oddDoubleChance.current
    //         oddOld.current = odd.current
    //     }, 3000);
    // }, [itemState])
    return (
        <>
            <div className='flex w-full gap-2'>
                {itemOddEvenState && itemOddEvenState.length > 0 && (
                    <div className='w-1/5 flex flex-col NFLstatus'>
                        <div className='flex items-center flex-col w-full'>
                            {/* bg-[#1E212B] flex flex-col rounded-tl-[6px] */}
                            <div className='bg-[#1E212B] rounded-4 flex flex-col mt-6 pr-[18px] rounded-[6px] soccerBar w-full p-2'>
                                <div className='flex w-full justify-center gap-2 topStatus'>
                                    <Space className=' justify-center flex items-center text-nowrap !text-[#E4E7F1] !text-sm !fw-[700]'>
                                        Odd Even
                                    </Space>
                                </div>
                            </div>
                            <div className='flex w-full mt-2'>
                                {itemOddEvenState && itemOddEvenState.length > 0 && itemOddEvenState[0].prices && itemOddEvenState[0].prices.length > 1
                                    ?
                                    <div className={`flex flex-col hdp ${itemOddEvenState[0]?.prices && itemOddEvenState[0]?.prices.length > 0 ? 'w-1/' + itemOddEvenState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddEven.current?.[0]?.prices?.[1]?.price, oddEvenOld.current?.[0]?.prices?.[1]?.price,
                                                () => (listChooseBet && oddEven.current?.[0]?.prices?.[1]?.price !== oddEvenOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemOddEvenState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemOddEvenState[0].id)].option === (itemOddEvenState[0]?.prices[1]?.option === 'h' ? 'Odd' : 'Even') ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemOddEvenState[0].id, itemOddEvenState[0]?.prices[1]?.option === 'h' ? 'Odd' : 'Even' + " " + itemOddEvenState[0].prices[1].price, itemOddEvenState[0]?.prices[1]?.option === 'h' ? 'Odd' : 'Even', homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemOddEvenState[0]?.prices[1]?.option === 'h' ? 'Odd' : 'Even', 1, 0, itemOddEvenState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetSoccerOEF?.(
                                                    // eslint-disable-next-line no-useless-concat
                                                    1, itemOddEvenState[0].id, homeTeam + " / " + awayTeam, itemOddEvenState[0]?.prices[1]?.option === 'h' ? `Odd ${itemOddEvenState[0]?.prices[1]?.price}` : `Even ${itemOddEvenState[0]?.prices[1]?.price}`, itemOddEvenState[0]?.prices[1]?.option === 'a' ? 'Even' : 'Odd'
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemOddEvenState[0]?.prices[1]?.option === 'h' ? 'Odd' : 'Even'}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemOddEvenState[0]?.prices[1]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddEven.current?.[0]?.prices?.[1]?.price, oddEvenOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                                {itemOddEvenState && itemOddEvenState.length > 0 && itemOddEvenState[0].prices && itemOddEvenState[0].prices.length > 0
                                    ?
                                    <div className={`flex flex-col hdp ${itemOddEvenState[0]?.prices && itemOddEvenState[0]?.prices.length > 0 ? 'w-1/' + itemOddEvenState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddEven.current?.[0]?.prices?.[0]?.price, oddEvenOld.current?.[0]?.prices?.[0]?.price,
                                                () => (listChooseBet && oddEven.current?.[0]?.prices?.[0]?.price !== oddEvenOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemOddEvenState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemOddEvenState[0].id)].option === (itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even') ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemOddEvenState[0].id, itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even' + " " + itemOddEvenState[0].prices[0].price, itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even', homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even', 0, 0, itemOddEvenState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetSoccerOEF?.(
                                                    // eslint-disable-next-line no-useless-concat
                                                    0, itemOddEvenState[0].id, homeTeam + " / " + awayTeam, itemOddEvenState[0]?.prices[0]?.option === 'h' ? `Odd ${itemOddEvenState[0].prices[0].price}` : `Even ${itemOddEvenState[0].prices[0].price}`, itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even'
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemOddEvenState[0]?.prices[0]?.option === 'h' ? 'Odd' : 'Even'}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemOddEvenState[0]?.prices[0]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddEven.current?.[0]?.prices?.[0]?.price, oddEvenOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                            </div>
                        </div>
                    </div>
                )}
                {itemState && itemState.length > 0 && (
                    <div className='w-2/5 flex flex-col NFLstatus'>
                        <div className='flex items-center flex-col w-full'>
                            <div className='bg-[#1E212B] rounded-4 flex flex-col mt-6 pr-[18px] rounded-[6px] soccerBar w-full p-2'>
                                <div className='flex w-full justify-center gap-2 topStatus'>
                                    <Space className=' justify-center flex items-center text-nowrap !text-[#E4E7F1] !text-sm !fw-[700]'>
                                        Total Goal
                                    </Space>
                                </div>

                            </div>
                            <div className='flex w-full mt-2'>

                                {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                    ?
                                    <div className={`flex flex-col hdp ${itemState[0]?.prices && itemState[0]?.prices.length > 0 ? 'w-1/' + itemState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price,
                                                () => (listChooseBet && odd.current?.[0]?.prices?.[0]?.price !== oddOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0]?.prices[0]?.option + " " + itemState[0].prices[0].price, itemState?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemState[0]?.prices[0]?.option, 0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetTop?.(
                                                    0, itemState[0].id, homeTeam + " / " + awayTeam, itemState[0]?.prices[0]?.option + " " + itemState[0].prices[0].price, itemState[0]?.prices[0]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemState[0]?.prices[0]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemState[0]?.prices[0]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                                {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 1
                                    ?
                                    <div className={`flex flex-col hdp ${itemState[0]?.prices && itemState[0]?.prices.length > 0 ? 'w-1/' + itemState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price,
                                                () => (listChooseBet && odd.current?.[0]?.prices?.[1]?.price !== oddOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0]?.prices[1]?.option + " " + itemState[0].prices[1].price, itemState[0]?.prices[1]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemState[0]?.prices[1]?.option, 1, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetTop?.(
                                                    1, itemState[0].id, homeTeam + " / " + awayTeam, itemState[0]?.prices[1]?.option + " " + itemState[0].prices[1].price, itemState[0]?.prices[1]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemState[0]?.prices[1]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemState[0]?.prices[1]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                                {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 2
                                    ?
                                    <div className={`flex flex-col hdp ${itemState[0]?.prices && itemState[0]?.prices.length > 0 ? 'w-1/' + itemState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[2]?.price, oddOld.current?.[0]?.prices?.[2]?.price,
                                                () => (listChooseBet && odd.current?.[0]?.prices?.[2]?.price !== oddOld.current?.[0]?.prices?.[2]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[2]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0]?.prices[2]?.option + " " + itemState[0].prices[2].price, itemState[0]?.prices[2]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemState[0]?.prices[2]?.option, 2, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetTop?.(
                                                    2, itemState[0].id, homeTeam + " / " + awayTeam, itemState[0]?.prices[2]?.option + " " + itemState[0].prices[2].price, itemState[0]?.prices[2]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemState[0]?.prices[2]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemState[0]?.prices[2]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[2]?.price, oddOld.current?.[0]?.prices?.[2]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                                {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 3
                                    ?
                                    <div className={`flex flex-col hdp ${itemState[0]?.prices && itemState[0]?.prices.length > 0 ? 'w-1/' + itemState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative  ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[3]?.price, oddOld.current?.[0]?.prices?.[3]?.price,
                                                () => (listChooseBet && odd.current?.[0]?.prices?.[3]?.price !== oddOld.current?.[0]?.prices?.[3]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[3]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0]?.prices[3]?.option + " " + itemState[0].prices[3].price, itemState[0]?.prices[3]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemState[0]?.prices[3]?.option, 3, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Total Goal")}`}
                                                onClick={() => chooseBetTop?.(
                                                    3, itemState[0].id, homeTeam + " / " + awayTeam, itemState[0]?.prices[3]?.option + " " + itemState[0].prices[3].price, itemState[0]?.prices[3]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap !min-w-0 text-[16px]'>{itemState[0]?.prices[3]?.option}</p>
                                                <p className='!min-w-0'><b className='text-[#E4E7F1] text-[18px]'>{Number(itemState[0]?.prices[3]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[3]?.price, oddOld.current?.[0]?.prices?.[3]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}
                            </div>
                        </div>
                    </div>
                )}
                {itemDoubleChanceState && itemDoubleChanceState.length > 0 && (
                    <div className='w-2/5 flex flex-col NFLstatus'>
                        <div className='flex items-center flex-col w-full'>
                            <div className='bg-[#1E212B] rounded-4 flex flex-col mt-6 pr-[18px] rounded-[6px] soccerBar w-full p-2'>
                                <div className='flex w-full justify-center gap-2 topStatus'>
                                    <Space className=' justify-center flex items-center text-nowrap !text-[#E4E7F1] !text-sm !fw-[700]'>
                                        Double Chance
                                    </Space>
                                </div>

                            </div>
                            <div className='flex w-full mt-2'>
                                {itemDoubleChanceState && itemDoubleChanceState.length > 0 && itemDoubleChanceState[0].prices && itemDoubleChanceState[0].prices.length > 0
                                    ?
                                    <div className={`flex flex-col hdp ${itemDoubleChanceState[0]?.prices && itemDoubleChanceState[0]?.prices.length > 0 ? 'w-1/' + itemDoubleChanceState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddDoubleChance.current?.[0]?.prices?.[0]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[0]?.price,
                                                () => (listChooseBet && oddDoubleChance.current?.[0]?.prices?.[0]?.price !== oddDoubleChanceOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemDoubleChanceState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemDoubleChanceState[0].id)].option === itemDoubleChanceState[0]?.prices[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemDoubleChanceState[0].id, itemDoubleChanceState[0]?.prices[0]?.option + " " + itemDoubleChanceState[0].prices[0].price, itemDoubleChanceState[0]?.prices[0]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemDoubleChanceState[0]?.prices[0]?.option, 0, 0, itemDoubleChanceState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Double Chance")}`}
                                                onClick={() => chooseBetTopDoubleChance?.(
                                                    0, itemDoubleChanceState[0].id, homeTeam + " / " + awayTeam, itemDoubleChanceState[0]?.prices[0]?.option + " " + itemDoubleChanceState[0].prices[0].price, itemDoubleChanceState[0]?.prices[0]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap !min-w-0 text-[16px]'>{itemDoubleChanceState[0]?.prices[0]?.option}</p>
                                                <p className='!min-w-0'><b className='text-[#E4E7F1] text-[18px]'>{Number(itemDoubleChanceState[0]?.prices[0]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddDoubleChance.current?.[0]?.prices?.[0]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}

                                {itemDoubleChanceState && itemDoubleChanceState.length > 0 && itemDoubleChanceState[0].prices && itemDoubleChanceState[0].prices.length > 1
                                    ?
                                    <div className={`flex flex-col hdp ${itemDoubleChanceState[0]?.prices && itemDoubleChanceState[0]?.prices.length > 0 ? 'w-1/' + itemDoubleChanceState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddDoubleChance.current?.[0]?.prices?.[1]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[1]?.price,
                                                () => (listChooseBet && oddDoubleChance.current?.[0]?.prices?.[1]?.price !== oddDoubleChanceOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemDoubleChanceState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemDoubleChanceState[0].id)].option === itemDoubleChanceState[0]?.prices[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemDoubleChanceState[0].id, itemDoubleChanceState[0]?.prices[1]?.option + " " + itemDoubleChanceState[0].prices[1].price, itemDoubleChanceState[0]?.prices[1]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemDoubleChanceState[0]?.prices[1]?.option, 1, 0, itemDoubleChanceState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Double Chance")}`}
                                                onClick={() => chooseBetTopDoubleChance?.(
                                                    1, itemDoubleChanceState[0].id, homeTeam + " / " + awayTeam, itemDoubleChanceState[0]?.prices[1]?.option + " " + itemDoubleChanceState[0].prices[1].price, itemDoubleChanceState[0]?.prices[1]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemDoubleChanceState[0]?.prices[1]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemDoubleChanceState[0]?.prices[1]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddDoubleChance.current?.[0]?.prices?.[1]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}

                                {itemDoubleChanceState && itemDoubleChanceState.length > 0 && itemDoubleChanceState[0].prices && itemDoubleChanceState[0].prices.length > 2
                                    ?
                                    <div className={`flex flex-col hdp ${itemDoubleChanceState[0]?.prices && itemDoubleChanceState[0]?.prices.length > 0 ? 'w-1/' + itemDoubleChanceState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddDoubleChance.current?.[0]?.prices?.[2]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[2]?.price,
                                                () => (listChooseBet && oddDoubleChance.current?.[0]?.prices?.[2]?.price !== oddDoubleChanceOld.current?.[0]?.prices?.[2]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemDoubleChanceState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemDoubleChanceState[0].id)].option === itemDoubleChanceState[0]?.prices[2]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemDoubleChanceState[0].id, itemDoubleChanceState[0]?.prices[2]?.option + " " + itemDoubleChanceState[0].prices[2].price, itemDoubleChanceState[0]?.prices[2]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemDoubleChanceState[0]?.prices[2]?.option, 2, 0, itemDoubleChanceState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "OddEven")}`}
                                                onClick={() => chooseBetTopDoubleChance?.(
                                                    2, itemDoubleChanceState[0].id, homeTeam + " / " + awayTeam, itemDoubleChanceState[0]?.prices[2]?.option + " " + itemDoubleChanceState[0].prices[2].price, itemDoubleChanceState[0]?.prices[2]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemDoubleChanceState[0]?.prices[2]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemDoubleChanceState[0]?.prices[2]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddDoubleChance.current?.[0]?.prices?.[2]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[2]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}

                                {itemDoubleChanceState && itemDoubleChanceState.length > 0 && itemDoubleChanceState[0].prices && itemDoubleChanceState[0].prices.length > 3
                                    ?
                                    <div className={`flex flex-col hdp ${itemDoubleChanceState[0]?.prices && itemDoubleChanceState[0]?.prices.length > 0 ? 'w-1/' + itemDoubleChanceState[0]?.prices.length : 'w-full'}`}>
                                        <div className={`justify-center w-full flex items-center`}
                                        >
                                            <div className={`ratioDetail ratioDetailSoccer flex flex-col justify-between w-full rounded-md relative ${getChangeColorSoccer_V3(oddDoubleChance.current?.[0]?.prices?.[3]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[3]?.price,
                                                () => (listChooseBet && oddDoubleChance.current?.[0]?.prices?.[3]?.price !== oddDoubleChanceOld.current?.[0]?.prices?.[3]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemDoubleChanceState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemDoubleChanceState[0].id)].option === itemDoubleChanceState[0]?.prices[3]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemDoubleChanceState[0].id, itemDoubleChanceState[0]?.prices[3]?.option + " " + itemDoubleChanceState[0].prices[3].price, itemDoubleChanceState[0]?.prices[3]?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemDoubleChanceState[0]?.prices[3]?.option, 3, 0, itemDoubleChanceState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "OddEven")}`}
                                                onClick={() => chooseBetTopDoubleChance?.(
                                                    3, itemDoubleChanceState[0].id, homeTeam + " / " + awayTeam, itemDoubleChanceState[0]?.prices[3]?.option + " " + itemDoubleChanceState[0].prices[3].price, itemDoubleChanceState[0]?.prices[3]?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] whitespace-nowrap text-[16px]'>{itemDoubleChanceState[0]?.prices[3]?.option}</p>
                                                <p><b className='text-[#E4E7F1] text-[18px]'>{Number(itemDoubleChanceState[0]?.prices[3]?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(oddDoubleChance.current?.[0]?.prices?.[3]?.price, oddDoubleChanceOld.current?.[0]?.prices?.[3]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""}

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default StatusBarSoccerMoreTab;