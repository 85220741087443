import { Button, Flex, Input, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useLoading from "../../hook/useLoading";
import useToast from "../../hook/useToast";
import * as loginApi from "../../api/login/loginApi";
import tokenService from '../../services/token';
import { useNavigate } from "react-router-dom";
import { addLoadmoneyInBillAcceptor } from "../../api/billAcceptor/billAcceptorApi";
import { setUser } from "../../store/slices/userSlice";


const Login: React.FC = () => {

  const navigator = useNavigate()

  const dispatch = useDispatch();
  const pushToast = useToast();
  const pushLoading = useLoading();

  const [username, setUsername] = useState("admin")
  const [password, setPassword] = useState("demo@123")

  const login = async () => {
    if (username.trim().length < 5 && username.trim().length > 20) {
      // pushToast("Email invalidate!", "warning", 2500)
      return
    }
    if (password.trim().length < 5 && password.trim().length > 20) {
      // pushToast("Email invalidate!", "warning", 2500)
      return
    }
    pushLoading(true);
    const ketQua = await loginApi.login(username.trim(), password.trim())
    if (ketQua?.success) {
      tokenService.setToken(ketQua.token)
      // setReduxUser(ketQua.data, ketQua.token)
      aLoadmoneyInBillAcceptor()
    } else {
      // pushToast(ketQua?.message || "", "warning");
    }
    pushLoading(false);
  }

  const aLoadmoneyInBillAcceptor = async () => {
    pushLoading(true);
    const ketQua = await addLoadmoneyInBillAcceptor("1", "1000");
    if (ketQua?.success) {
      dispatch(
        setUser({
          userId: ketQua.data.machineId,
          presentMoney: ketQua.data.presentMoney,
          authCode: '',
          machineCode: ''
        })
      )
      navigator("/");
    } else {

      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }


  return (
    <Flex gap="middle" wrap="wrap">
      <Layout className='w-full'>

        <Content className='bg-[#F0F0F0] min-h-[2000px] pt-[34px] pr-[20px] pb-[34px] pl-[20px] text-center'>
          <Input
            size="large"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <br />
          <Input
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="primary" danger
            className="mt-5"
            onClick={login}
          >Login</Button>
        </Content>
      </Layout>
    </Flex>
  )
}




export default Login;